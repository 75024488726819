import { Injectable } from '@angular/core';
import {Http, Response, RequestOptions,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { AppComponent } from '../../app.component';

@Injectable()
export class PagosService {

  private detalle = {};

  constructor(private http: Http,private appComp : AppComponent) {
  }

  // TODO: observables
  savePrimerMetodoPago(token,name,email,id){
    let body = 'token='+token+'&name='+name+'&email='+email+'&id='+id;
    return this.http.post(this.appComp.apiUrl+"newCustomer",body,this.appComp.options).map((res : Response) => res.json());
  }

  saveNewMetodoPago(id,customer_id,token){
    let body = 'idUsuario='+id+'&customer_id='+customer_id+'&token='+token;
    return this.http.post(this.appComp.apiUrl+"newCardCustomer",body,this.appComp.options).map((res : Response) => res.json());
  }

  detalleEDC(mes,anio,asociado){
    let body = 'month='+mes+'&asociado='+asociado+'&year='+anio;	 
    return this.http.post(this.appComp.apiUrl+"getStatusAccountByMonth",body,this.appComp.options).map((res : Response) => res.json());
  }

  pagarEstadoCuenta(correo,idUsuario,customerID,mes,anio,monto,asociado,id_card){
    let body = '&correo='+correo+'&idUsuario='+idUsuario+'&customer_id='+customerID+'&month='+mes+'&year='+anio+'&monto='+monto+'&asociado='+asociado+'&id_card='+id_card;
    return this.http.post(this.appComp.apiUrl+"newOrder",body,this.appComp.options).map((res : Response) => res.json());
  }

  getListaTarjetas(customerID){
    let body = 'customer_id='+customerID;
    return this.http.post(this.appComp.apiUrl+"dataCustomerConekta",body,this.appComp.options).map((res : Response) => res.json());
  }

  saveReservacion(id,asociado,customer_id,hoyo,hora,fecha,monto,id_card,invitados){
    let body = 'idUsuario='+id+'&asociado='+asociado+'&customer_id='+customer_id+'&hoyo='+hoyo+'&fecha='+fecha+'&hora='+hora+'&monto='+monto+'&id_card='+id_card+'&invitados='+JSON.stringify(invitados);
    console.log(body);
    return this.http.post(this.appComp.apiUrl+"reservacion",body,this.appComp.options).map((res : Response) => res.json());
  }

  updateReservacion(idReservacion, hoyo, hora, fecha, invitados, invitadosUpdate, idUsuario){
    let body = 'idReservacion=' + idReservacion + '&hoyo=' + hoyo + '&hora=' + hora + '&fecha=' + fecha + '&invitados=' + JSON.stringify(invitados) + '&invitadosUpdate=' + JSON.stringify(invitadosUpdate)  + '&idUsuario=' + idUsuario;
    console.log(body);
    return this.http.post(this.appComp.apiUrl+"updateReservation",body,this.appComp.options).map((res : Response) => res.json());
  }

  eliminarInvitadoReservacion( id ){
    let body = 'id='+id
    return this.http.post(this.appComp.apiUrl +"deleteGuestReservation",body,this.appComp.options).map((res : Response) => res.json());
  }

  cancelarReservacion(idReservacion){
    let body = 'idReservacion='+idReservacion;
    console.log(body);
    return this.http.post(this.appComp.apiUrl+"cancelarReservacion",body,this.appComp.options).map((res : Response) => res.json());
  }
  
  eliminarTarjeta(card_id,customer_id){
    console.log(card_id);
    let body = 'card_id='+card_id+'&customer_id='+customer_id;
    return this.http.post(this.appComp.apiUrl+"deleteCard",body,this.appComp.options).map((res : Response) => res.json());
  }

  getData(){
    return this.detalle;
  }
}
