import { Injectable } from '@angular/core';
import {Http, Response, RequestOptions,Headers, ResponseContentType} from '@angular/http';
import 'rxjs/add/operator/map';
import { AppComponent } from '../../app.component';
import { Observable } from 'rxjs';

@Injectable()
export class ReservasGolfService {

  private data = [];

  constructor(private http: Http,private appComp : AppComponent) {
  }

  getHoyos(fecha,estatus){
    let body = 'fecha='+fecha+'&estatus=' + estatus;
    return this.http.post(this.appComp.apiUrl+"hoyos",body,this.appComp.options).map((res : Response) => res.json());
    
  }

  getReservaciones(fecha, fecha2){
    let body = 'fecha=' + fecha +'&fecha2=' + fecha2;
    return this.http.post(this.appComp.apiUrl+"allReservation",body,this.appComp.options).map((res : Response) => res.json());
  }

  getFileReservaciones(fecha, fecha2):Observable<any>{
    let options = new RequestOptions({responseType: ResponseContentType.Blob});
    return this.http.get(this.appComp.apiUrl+"allReservation/descarga?fecha="+fecha+"&fecha2="+fecha2, options)
        .map((response: Response) => <Blob>response.blob());
  }

validaReservacionesSocio(asociado,fecha,hoyo,hora){
  let body = 'fecha='+fecha+'&hoyo=' + hoyo +'&hora=' + hora +'&asociado=' + asociado;
  console.log(body);
  return this.http.post(this.appComp.apiUrl+"validaReservacionesSocio",body,this.appComp.options).map((res : Response) => res.json());
  
}

  // TODO: observables
  getData() {
    return this.data;
  }
}