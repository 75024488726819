import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { AppComponent } from '../../../app.component';
import { PagosService } from '../../../@core/data/pagos.service';

declare var Conekta:any;
Conekta.setPublishableKey("key_VoyZkMH6PFFSGrmGb6f5sfA");

@Component({
  selector: 'ngx-modal',
  templateUrl : './modal-tarjetas.component.html'
})
export class ModalTarjetasComponent {
  addNewTarjeta = false;
  tarjetas = [];
  brand = "";
  nuevaTarjeta = {propietario : '',numeroTarjeta : '',mesExpiracion : '',anioExpiracion : '',cvc : ''};
  modalHeader: string;
  modalType: string;
  viewMontoPago = false;
  config : ToasterConfig;
  pago = {montoPago : 0, mes : "", anio:""};
  user_terralta = JSON.parse(localStorage.getItem("user_terralta"));
  tarjetaSeleccionada : any;
  montoPagoOtraCantidad = "";
  radioMontoPago = false;

  constructor(private activeModal: NgbActiveModal,
              private toasterService: ToasterService,
              private appComp : AppComponent,
              private pagosSrv : PagosService) { 
                this.getListaTarjetas();
              }

  closeModal() {
    this.activeModal.close();
  }

  changeCardNumber(){
    this.brand = Conekta.card.getBrand(this.nuevaTarjeta.numeroTarjeta);
  }

  validarTarjeta(event){
    event.preventDefault();
    if(!Conekta.card.validateNumber(this.nuevaTarjeta.numeroTarjeta)){
      this.showToast('warning', "Error en Número de Tarjeta", "Error en el formato del número de tarjeta");
      return false;
    }
    if(!Conekta.card.validateExpirationDate(this.nuevaTarjeta.mesExpiracion,this.nuevaTarjeta.anioExpiracion)){
      this.showToast('warning', "Error en Fecha de Expiración", "Error en el formato de la fecha de expiración");
      return false;
    }
    if(!Conekta.card.validateCVC(this.nuevaTarjeta.cvc)){
      this.showToast('warning', "Error en CVC", "Error de formato en CVC");
      return false;
    }
  
    this.tokenizarTarjeta();
  }
  
  tokenizarTarjeta(){
      Conekta.token.create(
        {card:
          { number:this.nuevaTarjeta.numeroTarjeta,
            name:this.nuevaTarjeta.propietario,
            exp_year:this.nuevaTarjeta.anioExpiracion,
            exp_month:this.nuevaTarjeta.mesExpiracion,
            cvc: this.nuevaTarjeta.cvc 
          }
        },(token)=>{ 
          if(this.tarjetas.length>0){
            
            this.saveNewMetodoPago(token.id);
          }
          else{
            this.saveMetodoPago(token.id);
          }
          },(error)=>{ console.error(error); 
            this.showToast('warning', "Tokenizar Tarjeta", "Error al Tokenizar la tarjeta");
        });
    }


  saveMetodoPago(token){
    this.pagosSrv.savePrimerMetodoPago(token,this.nuevaTarjeta.propietario,this.user_terralta.correo,this.user_terralta.id).subscribe(
      data => {
        if(data.success){
          
          this.user_terralta.customer_id = data.customer_id;
          console.log(data);
          console.log(this.user_terralta);
          localStorage.setItem("user_terralta",JSON.stringify(this.user_terralta));

          this.showToast('success', "Registro exitoso", "Se ha amacenado su tarjeta exitosamente.");
          this.addNewTarjeta = false;
          this.getListaTarjetas();
        }
        else{
          this.showToast('danger', "Error en el registro", "Se ha producido un error al registrar la tarjeta.");
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
      }
    );
  }

  saveNewMetodoPago(token){
    this.pagosSrv.saveNewMetodoPago(this.user_terralta.id,this.user_terralta.customer_id,token).subscribe(
      data => {
        if(data.success){
          // this.user_terralta.customer_id = data.customer_id;
          // console.log(data);
          // console.log(this.user_terralta);
          // localStorage.setItem("user_terralta",JSON.stringify(this.user_terralta));

          this.showToast('success', "Registro exitoso", "Se ha almacenado su tarjeta exitosamente.");
          this.addNewTarjeta = false;
          this.getListaTarjetas();
        }
        else{
          this.showToast('danger', "Error en el registro", "Se ha producido un error al registrar la tarjeta.");
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
      }
    );
  }

  getListaTarjetas(){
    console.log(this.user_terralta);
    this.pagosSrv.getListaTarjetas(this.user_terralta.customer_id).subscribe(
      data => {
          console.log(data);
        if (data instanceof Array){
          this.tarjetas = data;
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
      }
    );
  }

  mostrarMontoPago(tarjeta){
    this.viewMontoPago=true;
    this.addNewTarjeta=false
    this.tarjetaSeleccionada = tarjeta;
  }

  confirmPago(){
    console.log(this.user_terralta);
    console.log(this.pago);
    console.log(this.tarjetaSeleccionada);
    console.log(this.radioMontoPago);
    var r = window.confirm("Confirmar pago de $"+this.pago.montoPago+" para "+this.pago.mes+" "+this.pago.anio+" con la terjeta en terminación ************"+this.tarjetaSeleccionada.last4);
    if (r == true) {
        this.pagar();
    }
  }

  confirmDeleteTarjeta(card,index){
    console.log(card.id);
    console.log(this.user_terralta.customer_id);
    var r = window.confirm("Confirmar eliminación de la tarjeta ****"+card.last4);
    if (r == true) {
        this.deleteTarjeta(card.id,index);
    }
  }

  deleteTarjeta(card_id,index){
    
    this.pagosSrv.eliminarTarjeta(card_id,this.user_terralta.customer_id).subscribe(
      data => {
        console.log(data);
        if(data.success){
          this.showToast('success', "Tarjeta Eliminada", "Se ha dado de baja la tarjeta");
          this.tarjetas.splice(index,1);
        }
        else{
          this.showToast('danger', "Error.", "Hubo un error al eliminar la tarjeta, vuelva a intentarlo o contacte a su administrador.");
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
      }
    );
  }
  
  pagar(){
    this.pagosSrv.pagarEstadoCuenta(this.user_terralta.correo,this.user_terralta.id,this.user_terralta.customer_id,this.pago.mes,this.pago.anio,this.pago.montoPago,this.user_terralta.asociado,this.tarjetaSeleccionada.id).subscribe(
      data => {
        console.log(data);
        if(data.success){
          this.showToast('success', "Pago realizado", "Su pago se ha procesado con éxito.");
          setTimeout(()=>{
            this.addNewTarjeta = false;
            this.closeModal();
          }, 3000);
        }
        else{
          this.showToast('danger', "Error.", "Hubo un error al pagar, vuelva a intentarlo o contacte a su administrador.");
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
      }
    );
  }

  private showToast(type: string, title: string, body: string) {
    this.config = new ToasterConfig({
      positionClass: 'toast-top-right',
      timeout: 5000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: 'fade',
      limit: 5,
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      timeout: 3000,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }


}
