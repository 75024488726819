import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ServicesautosService } from '../../../services/servicesautos.service';
import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';


@Component({
  selector: 'modal-autos',
  templateUrl: './modal-autos.component.html',
  styles: []
})
export class ModalAutosComponent{
  matricula: string;
  config: ToasterConfig;

  constructor(private activeModal: NgbActiveModal,
              private autosServices: ServicesautosService,
              private toasterService: ToasterService) { }

  permitir(){
    this.autosServices.generarAccesso(this.matricula)
      .subscribe(
        (data) => {
          console.log(" ???? acesso permitod ", data);
          this.showToast("success","Accesso Permitido", data['_body']);
          setTimeout(() => {
            this.activeModal.close();
          }, 5000);
        },
        (error) => {
          console.error("   ?? ? ? ? ", error);
        }
      )
  }

  private showToast(type: string, title: string, body: string) {
    this.config = new ToasterConfig({
      positionClass: 'toast-top-right',
      timeout: 5000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: 'fade',
      limit: 5,
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      timeout: 5000,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }
}
