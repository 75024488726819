
import { Injectable } from '@angular/core';
import {Http, Response, RequestOptions,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { AppComponent } from '../../app.component';

let counter = 0;

@Injectable()
export class UserService {

  private users = {};

  private userArray: any[];

  constructor(private http: Http,private appComp : AppComponent) {
    // this.userArray = Object.values(this.users);
  }

  // getUsers(): Observable<any> {
  //   return observableOf(this.users);
  // }

  // getUserArray(): Observable<any[]> {
  //   return observableOf(this.userArray);
  // }

  // getUser(): Observable<any> {
  //   counter = (counter + 1) % this.userArray.length;
  //   return observableOf(this.userArray[counter]);
  // }

  updateContrasenia(idUsuario,nuevaContrasenia){
    let body = 'idUsuario='+idUsuario+'&contrasenia='+nuevaContrasenia;
    return this.http.post(this.appComp.apiUrl+"insertPass",body,this.appComp.options).map((res : Response) => res.json());
  }

  dataGuest(asociado){
    let body = 'asociado='+asociado;
    return this.http.post(this.appComp.apiUrl+"dataGuest",body,this.appComp.options).map((res : Response) => res.json());
  }


  getSearchUser(name){
    return this.http.get(this.appComp.apiUrl + "search/user?nombre_socio="+name, this.appComp.options).map((res: Response) => res.json());
  }
}
