import { Injectable } from '@angular/core';
import {Http, Response, RequestOptions,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { AppComponent } from '../../app.component';

@Injectable()
export class SmartTableService {

  private data = [];

  // data = [{
  //   id: 1,
  //   hoyo: 1,
  //   fecha: '16 Junio 2018',
  //   hora: '09:30',
  //   invitados: 0,
  // },
  // {
  //   id: 2,
  //   hoyo: 10,
  //   fecha: '17 Junio 2018',
  //   hora: '10:30',
  //   invitados: 2,
  // }];

  constructor(private http: Http,private appComp : AppComponent) {
  }

  getListaReservaciones(asociado){
    let body = 'asociado='+asociado;
    return this.http.post(this.appComp.apiUrl+"listadoReservaciones",body,this.appComp.options).map((res : Response) => res.json());
  }

  getListaAllReservaciones(){
    return this.http.get(this.appComp.apiUrl + "listadoReservaciones",this.appComp.options).map((res: Response) => res.json());
  }

  getUserdata(asociado){
    let body = 'asociado=' + asociado;
    return this.http.post(this.appComp.apiUrl + "/user/data", body, this.appComp.options).map((res: Response) => res.json());
  }
  

  getData() {
    return this.data;
  }
}
