import { Component } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Observable } from "rxjs/Observable";
import { Subject } from 'rxjs/Subject';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { MENU_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  template: `
    <ngx-sample-layout>
      <nb-menu [items]="menu"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-sample-layout>
  `,
})
export class PagesComponent {
  menu = [];
  private isMenuLoaded = new BehaviorSubject<any>(true);
  menu1 = this.isMenuLoaded.asObservable();

  constructor(){
    this.loadMenu(this.menu1);
  }
  loadMenu(menu1) {
console.log("CARGAR MENU");
    let user_terralta = JSON.parse(localStorage.getItem("user_terralta"));
    let permisosCasaClub: any;
    let permisosResidente: any;
    let permisosStarters: any;

    let permisos = Array();

    if (user_terralta) {
      let user_permisos = user_terralta.permisos.split(",");
      if (user_permisos.indexOf('casaclub') >= 0) {
        permisosCasaClub = {
          title: 'Casa Club & Golf',
          icon: 'nb-home',
          link: '/pages/estados-cuenta',
          home: true,
          children: [
            {
              title: 'Campo de Golf',
              icon: 'nb-sunny',
              link: '/pages/reservaciones'
            }, {
              title: 'Estados de Cuenta',
              icon: 'nb-list',
              link: '/pages/estados-cuenta'
            },
            // {
            //   title: 'Autos Registrados',
            //   icon: 'nb-keypad',
            //   link: '/pages/autos-usuario'
            // }  
          ],
        };
        permisos.push(permisosCasaClub);
      }

      if (user_permisos.indexOf('residente') >= 0) {
        permisosResidente = {
          title: 'Condómino',
          icon: 'nb-location',
          link: '/pages/residente'
        };
        permisos.push(permisosResidente);
      }

      if (user_permisos.indexOf('starters') >= 0) {

        permisosStarters = {
          title: 'Staft',
          icon: 'nb-star',
          link: '/pages/starters',
          children: [
            {
              title: 'Reservaciones',
              icon: 'nb-layout-two-column',
              link: '/pages/starters'
            },
            {
              title: 'Crear Reservacion',
              icon: 'nb-plus',
              link: '/pages/reservacionstaff'
            },
            {
              title: 'Estados de Cuenta',
              icon: 'nb-list',
              link: '/pages/estados-cuenta'
            }   
            // ,{
            //   title: 'Autos Registrados',
            //   icon: 'nb-keypad',
            //   link: '/pages/autos'
            // }     
          ],
        }
        permisos.push(permisosStarters);
      }
    }

    this.menu = permisos;
  }
}
