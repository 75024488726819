import { Component, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService, ToasterConfig, Toast, BodyOutputType } from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';
import { AppComponent } from '../../../app.component';
import { PagosService } from '../../../@core/data/pagos.service';
import { ReservasGolfService } from '../../../@core/data/reservas-golf.service';
import { UserService } from '../../../@core/data/users.service';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';

import * as _ from 'lodash';
import { timeout } from 'rxjs/operators';

declare var Conekta: any;
Conekta.setPublishableKey("key_VoyZkMH6PFFSGrmGb6f5sfA");

@Component({
  selector: 'ngx-modal',
  templateUrl: './modal-reservacion-staff.component.html',
})

export class ModalReservacionStaffComponent implements AfterViewInit {
  costoPorPersona = 2500;
  costoTotal = 0;

  modalHeader: string;
  modalEstatus: string;
  modalHoyo = 0;
  modalFecha = "";
  modalHora = "";
  modalOcupados = 0;
  modalType = "";
  modalIDReservacion = "";
  config: ToasterConfig;
  user_terralta = JSON.parse(localStorage.getItem("user_terralta"));
  invitados = [];
  fechaMin = "";
  fechaMax = "";
  arrayHorarios = [];
  numReservacion = "";

  socioSearch: any;
  socio: any = {
    id: 0,
    asociado: "",
    nombreCompleto: "",
    correo: "",
    customer_id: ""
  };

  buscar : String;
  actualizarUsuario = true;

  searching = false;
  searchFailed = false;

  permisos = [];
  isStaff: boolean = false;

  disabledSiguiente = false;

  viewReservacion = true;
  viewTarjetas = false;
  viewPago = false;

  montoPago = 0;
  tarjetas = [];
  tarjetaSeleccionada: any = { id: '' };

  constructor(private activeModal: NgbActiveModal,
    private toasterService: ToasterService,
    private appComp: AppComponent,
    private pagosSrv: PagosService,
    private eService: ReservasGolfService,
    private router: Router,
    private userService: UserService) {

    this.permisos = this.user_terralta.permisos.split(",");
  }

  closeModal() {
    this.activeModal.close();
  }
  ngAfterViewInit() {

    if (this.modalType == "new") {
      // cargando los inputs  
      //let ocupados = this.modalOcupados > 0 ? this.modalOcupados - 1 : this.modalOcupados;
      let ocupados = 0;
      this.addInvitadosInput(ocupados);
      this.getListaTarjetas();
    }
    if (this.modalType == "update") {
      this.actualizarUsuario = false;
      // cargando los inputs  
      this.addInvitadosInput(this.invitados.length);
      this.getDiasFiltro();
      this.getHoyos();
    }

  }

  private addInvitadosInput(numero: number) {
    for (let index = numero; index < 4; index++) {
      this.invitados.push({ asociado: '', nombre: '', email: '', autocomplete: this.modalType == 'new' ? true : false,search:'' });
    }
  }

  getDiasFiltro() {
    var d = new Date();
    d.setDate(d.getDate());
    for (var i = 0; i < 7; i++) {
      d.setDate(d.getDate() + 1);

      let mes = ((d.getMonth() + 1) < 10) ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
      let dia = (d.getDate() < 10) ? "0" + d.getDate() : d.getDate();

      let fechaFormatted = d.getFullYear() + "-" + mes + "-" + dia;
      if (i == 0) {
        this.fechaMin = fechaFormatted;
      }
      else if (i == 6) {
        this.fechaMax = fechaFormatted;
      }
    }
  }

  getHoyos() {
    this.eService.getHoyos(this.modalFecha, 2).subscribe(data => {
      let horariosHoyo = (this.modalHoyo == 1) ? 0 : 1;
      this.arrayHorarios = data[horariosHoyo];
    });
  }

  deleteInvitadoUpdate(index, invitado) {
    //this.invitados.splice(index, 1);
    if (invitado.id != undefined) {
      this.pagosSrv.eliminarInvitadoReservacion(invitado.id)
        .subscribe(
          data => {
            this.invitados[index] = { asociado: '', nombre: '', email: '', autocomplete: true };
            this.showToast('success', "Invitado eliminado", data.response);
          },
          error => {
            this.showToast('danger', "Error al eliminar invitado ", error._body);
            console.error(error._body);
          }
        )
    }
  }

  validarNumeroSocio(index) {
    if (this.invitados[index].asociado != '') {
      this.userService.dataGuest(this.invitados[index].asociado).subscribe(
        data => {
          if (data.data.length > 0) {
            console.log(data.data[0].nombreCompleto);
            console.log(this.invitados[index].nombre);
            this.invitados[index].nombre = data.data[0].nombreCompleto;
            this.invitados[index].buscar = data.data[0].nombreCompleto;
            this.invitados[index].email = data.data[0].correo;
          }
          else {
            this.invitados[index].asociado = "";
            this.invitados[index].nombre = "";
            this.invitados[index].email = "";
          }
        },
        err => {
          this.showToast('danger', "Error en el registro", err._body);
          console.log(err._body);
        }
      );
    }
  }

  formatter = (x: { nombreCompleto: string }) => x.nombreCompleto || '';
  search = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .do(() => this.searching = true)
      .switchMap(term =>
        this.userService.getSearchUser(this.socioSearch)
          .do(() => this.searchFailed = false)
          .catch(() => {
            this.searchFailed = true;
            return Observable.of([]);
          }))
      .do(() => this.searching = false);

  searchInvitado = (text$: Observable<string>) =>
    text$
      .debounceTime(300)
      .distinctUntilChanged()
      .do(() => this.searching = true)
      .switchMap(term =>
        this.userService.getSearchUser(this.buscar)
          .do(() => this.searchFailed = false)
          .catch(() => {
            this.searchFailed = true;
            return Observable.of([]);
          }))
      .do(() => this.searching = false);

  selectedItem(item, invitado) {
    invitado.asociado = item.item.asociado;
    invitado.email = item.item.correo;
    invitado.nombre = item.item.nombreCompleto;
    invitado.update = true;
    this.buscar = "";
  }

  selectedItemSocio(item, socio) {
    console.log(socio);
    console.log(item);
    this.socio = item.item
    // this.buscar = "";
  }

  modelForSearch(nombre,index) {
    this.buscar = nombre;
  }

  hideAutoComplte(invitado: any) {
    if (invitado.nombre == "") {
      invitado.autocomplete = true;
    }
  }

  mostrarMontoPago(tarjeta) {
    this.viewPago = true;
    this.viewTarjetas = false;
    this.disabledSiguiente = false;
    this.tarjetaSeleccionada = tarjeta;

    this.costoTotal = 0;
    for (var i = 0; i < this.invitados.length; i++) {
      if (this.invitados[i].asociado == '') {
        this.costoTotal += this.costoPorPersona;
      }
    }
  }

  siguiente() {
    if (this.viewReservacion) {
      //Validar campos de invitados
      if (this.invitados.length == 0) {
        this.viewReservacion = false;
        // this.viewTarjetas = false;
        this.viewPago = true;
        this.disabledSiguiente = false;
      }
      else if (this.validarInvitados()) {
        this.viewReservacion = false;
        this.viewPago = true;
        this.disabledSiguiente = false;
        this.invitados = _.filter(this.invitados, function (invitados) {
          if (typeof (invitados.nombre) == 'object') {
            invitados.nombre = invitados.nombre.nombreCompleto;
          }
          return invitados.update == true && invitados.nombre != "";
        });
      }

    }
    else if (this.viewPago) {
      this.disabledSiguiente = false;
      //Pagar
      if (this.costoTotal > 0) {
        this.confirmPago();
      }
      else if (this.costoTotal == 0) {
        this.guardarReservacion();
      }
    }
  }

  atras() {
    if (this.viewReservacion) {
      this.closeModal();
    }
    else if (this.viewTarjetas) {

      this.viewReservacion = true;
      // this.viewTarjetas = false;
      this.disabledSiguiente = false;
    }
    else if (this.viewPago) {

      this.addInvitadosInput(this.invitados.length);

      this.disabledSiguiente = false;
      this.viewReservacion = true;
      // this.viewTarjetas = true;
      this.viewPago = false;
    }
  }

  validarInvitados() {
    let flag = true;
    let aux = 0;
console.log(this.invitados);
    _.map(this.invitados, function (invitado) {
      if (invitado.nombre != '' || invitado.email != '') {
        invitado.errorName = invitado.nombre == '' ? 'input-error' : '';
        invitado.errorEmail = invitado.email == '' ? 'input-error' : '';
        if (invitado.errorName != '' || invitado.errorEmail != '') {
          flag = false;
        }
      }
    });

    if (!flag) {
      this.validateRequiredInput();
    }
    return flag;
  }

  validateRequiredInput() {
    let message = '';
    for (let index = 0; index < this.invitados.length; index++) {
      if (this.invitados[index].errorName == "input-error") {
        message += 'Campo Nombre requerido, ';
      }
      if (this.invitados[index].errorEmail == "input-error") {
        message += 'Campo Email requerido, ';
      }
    }
    this.showToast("error", " Campos Requeridos", message);
  }

  getListaTarjetas() {

    this.pagosSrv.getListaTarjetas(this.user_terralta.customer_id).subscribe(
      data => {
        this.tarjetas = data;
        console.log(data);
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
        console.log(err._body);
      }
    );
  }


  confirmPago() {
    var r = window.confirm("Confirmar pago de $" + this.costoTotal + " para esta reservación");
    if (r == true) {
      this.guardarReservacion();
    }
  }


  guardarReservacion() {
    this.disabledSiguiente = true;

    let userId = this.socio.id;
    let asociado = this.socio.asociado;
    let customer_id = this.socio.customer_id;

    if (userId == 0) {
      this.showToast("error", "Error ", "Debes seleccionar un socio con el autocomplete");
      return true;
    }

    this.pagosSrv.saveReservacion(
      userId,
      asociado,
      customer_id,
      this.modalHoyo,
      this.modalHora,
      this.modalFecha,
      this.costoTotal,
      this.tarjetaSeleccionada.id,
      this.invitados
    ).subscribe(
      data => {
        console.log(data);
        if (data.success) {
          this.showToast('success', "Reservación exitosa", data.response);
          this.numReservacion = data.numReservacion;
          // setTimeout(() => {
          //   this.closeModal();
          //   this.redirectTo('/pages/reservacionstaff');
          //   this.disabledSiguiente = false;
          // }, 3000);

        }
        else {
          this.showToast('danger', "Error en la reservación", data.error);
        }

      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
        console.log(err);
        this.disabledSiguiente = false;
      }
    );
  }

  changeTextInputInvitado(invitado) {
    invitado.update = true;
  }

  actualizarReservacion() {
    let temporalInvitados = _.filter(this.invitados, function (i) { return i.id == undefined && i.nombre != ""; });
    temporalInvitados = _.map(temporalInvitados, (i) => {
      if (typeof (i.nombre) == 'object') {
        i.nombre = i.nombre.nombreCompleto;
      }
      return i;
    });

    let updateDataInvitados = _.filter(this.invitados, function (i) { return i.update == true && i.id != undefined; });
    updateDataInvitados = _.map(updateDataInvitados, function (i) {
      if (typeof (i.nombre) == 'object') {
        i.nombre = i.nombre.nombreCompleto;
      }
      return i;
    });

    if (!this.validarInvitados()) {
      return false;
    }

    this.disabledSiguiente = true;
    this.pagosSrv.updateReservacion(
      this.modalIDReservacion,
      this.modalHoyo,
      this.modalHora,
      this.modalFecha,
      temporalInvitados,
      updateDataInvitados,
      this.user_terralta.id,
    ).subscribe(
      data => {
        if (data.success) {
          this.showToast('success', "Reservación actualizara", data.response);
          setTimeout(() => {
            this.closeModal();
            this.redirectTo('/pages/reservacionstaff');
            this.disabledSiguiente = false;
          }, 3000);
        }
        else {
          this.showToast('danger', "Error en la reservación", data.error);
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
        this.disabledSiguiente = false;
        console.log(err);
      }
    );
  }

  cancelarReservacion() {
    console.log(this.modalIDReservacion);
    this.pagosSrv.cancelarReservacion(this.modalIDReservacion).subscribe(
      data => {
        console.log(data);
        if (data.success) {
          this.showToast('success', "Su reservación ha sido cancelada", data.response);
          setTimeout(() => {
            this.closeModal();
            this.redirectTo('/pages/reservacionstaff');
          }, 3000);

        }
        else {
          this.showToast('danger', "Error en la reservación", data.error);
        }
      },
      err => {
        this.showToast('danger', "Error en el registro", err._body);
        console.log(err);
      }
    );
  }

  public redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  private showToast(type: string, title: string, body: string) {
    this.config = new ToasterConfig({
      positionClass: 'toast-top-right',
      timeout: 5000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: 'fade',
      limit: 5,
      showCloseButton : true
    });
    const toast: Toast = {
      type: type,
      title: title,
      body: body,
      timeout: 3000,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml,
    };
    this.toasterService.popAsync(toast);
  }
}
