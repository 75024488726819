import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { AnalyticsService } from '../../../@core/utils/analytics.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTarjetasComponent } from '../../../pages/componentes/modal-tarjetas/modal-tarjetas.component';
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {


  @Input() position = 'normal';

  user_terralta = JSON.parse(localStorage.getItem("user_terralta"));

  user: any;

  userMenu = [{ title: 'Cerrar Sesión',link : '/pages/logout'}];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private analyticsService: AnalyticsService,
              private modalService: NgbModal) {
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    return false;
  }

  ngOnInit() {
    this.user = { name: this.user_terralta.nombreCompleto, picture: '' };
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  showModalTarjetas(){
    const activeModal = this.modalService.open(ModalTarjetasComponent, { size: 'lg', container: 'nb-layout' });

    activeModal.componentInstance.modalHeader = 'Métodos de Pagos';
    activeModal.componentInstance.modalType = 'metodos';
    
  }

  showPDFHandicaps(){
    window.open('assets/images/handicap.pdf');
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
  

}
