import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-autos-registro',
  templateUrl: './modal-autos-registro.component.html',
  styleUrls: ['./modal-autos-registro.component.scss']
})
export class ModalAutosRegistroComponent implements OnInit {
  tipo: string;
  title: string;
  description: string;
  autoID:number = 0;
  
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit() {
    if(this.tipo == 'new'){
      this.title = 'Registrar Auto';
    }

    if(this.tipo == 'update'){
      this.title = 'Actualizar Registro del Auto';
    }

    if(this.tipo == 'delete'){
      this.title = "Eliminar Registro del Auto";
    }
  }


  guardar(){
    console.log(" GUADAR ");
  }

  actualizar(){
    console.log(" ACTUALIZAR ");
  }

  eliminar(){
    console.log(" Eliminar ");
  }

  cerrarModal(){
    this.activeModal.close();
  }

}
