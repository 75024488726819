import { Injectable } from '@angular/core';
import {Http, Response, RequestOptions,Headers} from '@angular/http';
import 'rxjs/add/operator/map';
import { AppComponent } from '../../app.component';

@Injectable()
export class EstadosCuentaService {

  private detalle = {};

  constructor(private http: Http,private appComp : AppComponent) {
  }

  // TODO: observables
  getEDCPeriodos(asociado,year,yearAnterior){
    let body = 'year='+year+'&yearAnterior='+yearAnterior+'&asociado='+asociado;
    return this.http.post(this.appComp.apiUrl+"getStatusAccountByYear",body,this.appComp.options).map((res : Response) => res.json());
    
  }

  detalleEDC(mes,anio,asociado){
    let body = 'month='+mes+'&asociado='+asociado+'&year='+anio;	 
    return this.http.post(this.appComp.apiUrl+"getStatusAccountByMonth",body,this.appComp.options).map((res : Response) => res.json());
  }

  getData(){
    return this.detalle;
  }
}
