import { Component, Injectable } from '@angular/core';;
import { EstadosCuentaService } from '../../../@core/data/estados-cuenta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTarjetasComponent } from '../../../pages/componentes/modal-tarjetas/modal-tarjetas.component';

@Injectable()
@Component({
  selector: 'ngx-detalle-edc',
  styleUrls: ['./detalle-edc.component.scss'],
  templateUrl: './detalle-edc.component.html',
})

export class DetalleEDCComponent{

  public data : any;
  mesActual : any;
  diaActual : any;
  user_terralta = JSON.parse(localStorage.getItem("user_terralta"));
  isStarter = false;

  constructor( private edcService : EstadosCuentaService,private modalService: NgbModal){
    this.data = {};
    this.data = this.edcService.getData();
    
    this.isStarter = (this.user_terralta.permisos.split(",").indexOf('starters') >= 0) ? true : false;

    let d = new Date();
     d.setDate(d.getDate());
     d.setDate(d.getDate() + 1);
     
     this.mesActual = ((d.getMonth()+1) < 10) ? "0" + (d.getMonth()+1) : (d.getMonth()+1);
     this.diaActual = (d.getDate() < 10) ? "0" + d.getDate() : d.getDate();
     
  }

  detalleEDC(mes,anio,asociado){
    
    this.edcService.detalleEDC(mes,anio,asociado).subscribe(data => { 
      let mesEstadoCuenta = parseInt(this.MesTextToNumber(mes));
      console.log('????',mesEstadoCuenta);
      console.log('????',this.mesActual);
        if(mesEstadoCuenta+1<this.mesActual && data.saldoALaFecha.estatus=="pendiente"){
          data.saldoALaFecha.estatus = "vencido";
        }
        // if(mesEstadoCuenta+1==this.mesActual && data.saldoALaFecha.estatus=="pendiente" && this.diaActual>7){
        //   data.saldoALaFecha.estatus = "vencido";
        // }


      this.data.monto = data.monto;
      this.data.fechaIni = data.fechaIni;
      this.data.fechaFin = data.fechaFin;
      this.data.saldoAnterior = (data.saldoAnterior.cargo == undefined) ? 0 : data.saldoAnterior.cargo;
      this.data.saldoCredito = (data.saldoAnterior.credito == undefined) ? 0 : data.saldoAnterior.credito;
      this.data.botonTitle = data.botonTitle;
      this.data.botonAction = data.botonAction;
      this.data.estatus = data.saldoALaFecha.estatus;
      this.data.desglose = data.desglose;
      this.data.pagos = data.pagos;
      this.data.mes = mes;
      this.data.anio = anio;
      this.data.mesLimitePago = this.mesPago();

      this.mesPago();
    });
  }

  MesTextToNumber(mesTexto){
    if(mesTexto == "Ene"){return "01";}
    else if(mesTexto == "Feb"){return "02";}
    else if(mesTexto == "Mar"){return "03";}
    else if(mesTexto == "Abr"){return "04";}
    else if(mesTexto == "May"){return "05";}
    else if(mesTexto == "Jun"){return "06";}
    else if(mesTexto == "Jul"){return "07";}
    else if(mesTexto == "Ago"){return "08";}
    else if(mesTexto == "Sep"){return "09";}
    else if(mesTexto == "Oct"){return "10";}
    else if(mesTexto == "Nov"){return "11";}
    else if(mesTexto == "Dic"){return "12";}
  }

  showModalTarjetas(){
    const activeModal = this.modalService.open(ModalTarjetasComponent, { size: 'lg', container: 'nb-layout' });
    
    activeModal.componentInstance.modalHeader = 'Realizar Pago';
    activeModal.componentInstance.modalType = 'pago';
    activeModal.componentInstance.pago.anio = this.data.anio;
    activeModal.componentInstance.pago.mes = this.data.mes;
    activeModal.componentInstance.pago.montoPago = this.data.monto;
  }

  mesPago(){
    
    if(this.data.mes=="Ene"){
      return "Feb";
    }
    else if(this.data.mes=="Feb"){
      return "Mar";
    }
    else if(this.data.mes=="Mar"){
      return "Abr";
    }
    else if(this.data.mes=="Abr"){
      return "May";
    }
    else if(this.data.mes=="May"){
      return "Jun";
    }
    else if(this.data.mes=="Jun"){
      return "Jul";
    }
    else if(this.data.mes=="Jul"){
      return "Ago";
    }
    else if(this.data.mes=="Ago"){
      return "Sep";
    }
    else if(this.data.mes=="Sep"){
      return "Oct";
    }
    else if(this.data.mes=="Oct"){
      return "Nov";
    }
    else if(this.data.mes=="Nov"){
      return "Dic";
    }
    else if(this.data.mes=="Dic"){
      return "Ene";
    }

  }
  
}

