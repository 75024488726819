import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, Headers } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class ServicesautosService {

  url: String = '/api/';
  public headers: Headers;
  public options: RequestOptions;

  constructor(private http: Http) {
    this.headers = new Headers({ 'Content-Type': 'application/json' });
    this.options = new RequestOptions({ headers: this.headers });
  }

  getListCards() {
    return this.http.get(this.url + "person/withvehiclelist").map((res: Response) => res.json());
  }

  generarAccesso(matricula: string) {
    return this.http.post(this.url + 'Access/Valid', `"${matricula}"`, this.options);
  }
}
